<template>
	<div class="box">
        <SeasonInput v-model="seasonSelected" @change="init_component"/>

        <CustomTable
            id_table="statistiques_repro"
            :items="season_stat"
            :busy.sync="table_busy"
            primaryKey="season_id"
            :hide_if_empty="true"
            :rowSelectable="false"
        />
    </div>
</template>

<script type="text/javascript">
    import GynecologieMixin from "@/mixins/Gynecologie.js"

	export default {
		name: 'StatistiquesRepro',
		mixins: [GynecologieMixin],
		data () {
			return {
				table_busy: true,
                season_stat: [],
                seasonSelected: null
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
                this.table_busy = true
                
                let season_id = this.seasonSelected ? this.seasonSelected.id : null
                let seasons_stat = await this.loadSeasonStat(season_id)
                if(seasons_stat) {
                    let temp = []
                    for(let i in seasons_stat) {
                        seasons_stat[i].season_start_date = seasons_stat[i].season_start
                        seasons_stat[i].season_end_date = seasons_stat[i].season_end
                        const total = seasons_stat[i].empty_mare + seasons_stat[i].full_mare + seasons_stat[i].waiting_mare
                        seasons_stat[i].percentage = (100 * seasons_stat[i].full_mare / total).toFixed(2) + "%"
                        temp.push(seasons_stat[i])
                    }

                    this.season_stat = temp
                } else {
                    this.season_stat = null
                }
                this.table_busy = false
			}
		},

		components: {
            CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
            SeasonInput : () => import('@/components/Inputs/SeasonInput')
		}
	}

</script>